import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ForwardRoute from "./components/Routes/ForwardRoute";

const Layout = lazy(() => import("./containers/Layout"));
const Auth = lazy(() => import("./containers/Auth"));
const Landing = lazy(() => import("./pages/Landing"));
const EmailVerification = lazy(() => import("./pages/EmailVerification"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Pricing = lazy(() => import("./pages/Pricing"));
const FAQs = lazy(() => import("./pages/FAQs"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const AcceptableUsePolicy = lazy(() => import("./pages/AcceptableUsePolicy"));
const TermsOfService = lazy(() => import("./pages/TermsOfService"));
const BlackFridayPromo = lazy(() => import("./pages/BlackFridayPromo"));

function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <ForwardRoute path="/auth" component={Auth} />
          {/* Place new routes over this */}
          <ProtectedRoute path="/app" component={Layout} />
          <Route exact path="/verify-email" component={EmailVerification} />
          <Route exact path="/" component={Landing} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/acceptable-use-policy"
            component={AcceptableUsePolicy}
          />
          <Route exact path="/cookies" component={CookiePolicy} />
          <Route exact path="/terms" component={TermsOfService} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/faqs" component={FAQs} />

          {/* Marketing LPs */}
          <Route exact path="/unlimited" component={BlackFridayPromo} />
          <Route exact path="/subtitle-generator" component={Landing} />
          <Route exact path="/topg" component={Landing} />

          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
